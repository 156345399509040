import React, { Component, Suspense, lazy } from 'react';
import { withRouter, Route, Switch } from "react-router-dom";
// import { Auth, Hub } from "aws-amplify";

// import AppHomePage from './pages/AppHomePage';
// import QuizzesPage from './pages/QuizzesPage'
// import ProfilePage from './pages/ProfilePage';
// import AuthPage from './pages/AuthPage';
import Navbar from './component/Navbar';
import './App.scss';
import CookieConsent, { OPTIONS } from "react-cookie-consent";

const AppHomePage = lazy(() => import('./pages/AppHomePage'));
const QuizzesPage = lazy(() => import('./pages/QuizzesPage'));

export const UserContext = React.createContext();

class App extends Component {

  // d2744347@urhen.com
  // vgprposxqgezuxmxtl@upived.online
  // triggr build

  state = {
    user: null,
    authError:false,
    loaded:true,
    userAttributes: null
  }

  // componentDidMount() {
  //   this.getUserData();
  //   Hub.listen('auth', this.onHubCapsule);
  // }

  // getUserData = async () => {
  //   console.log('getUserData')
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     if (user) {

  //       this.setState({ user, loaded:true });
  //       this.getUserAttributes(user);
  //       //setTimeout(() => this.props.history.push('/quiz'), 50);
  //     } else {
  //       this.setState({ user: null, loaded:true });
  //     }
  //   } catch (err) {
  //     this.setState({ user: null, loaded:true });
  //   }
  // }

  // getUserAttributes = async authUserData => {
  //   const attributesArr = await Auth.userAttributes(authUserData);
  //   const attributesObj = Auth.attributesToObject(attributesArr);
  //   this.setState({ userAttributes: attributesObj });
  // };

  // onHubCapsule = capsule => {
  //   //console.log('onHubCapsule ----------> ', capsule)
  //   this.setState({ authError: false });
  //   switch (capsule.payload.event) {
  //     case 'signIn':
  //       this.getUserData();
  //       break;
  //     case 'signIn_failure':
  //       console.log('signIn_failure');
  //       this.setState({ authError: true });
  //       break;
  //     case 'signUp':
  //       console.log('sign up');
  //       break;
  //     case 'signUp_failure':
  //       console.log('signUp_failure');
  //       this.setState({ authError: true });
  //       break;
  //     case 'signOut':
  //       console.log('signed out')
  //       this.setState({ user: null });
  //       break;
  //     default:
  //       return
  //   }
  // }

  // handleSignin = (e, _authState) => {
  //   this.props.history.push(`/${_authState === 'signIn' ? 'signin' : 'signup'}`);
  // }

  // handleSignout = async () => {
  //   try {
  //     await Auth.signOut();
  //     this.props.history.push('/');

  //   } catch (err) {
  //     console.error('Error signing out user', err);
  //   }
  // }

  render() {
    const { user, userAttributes, loaded } = this.state;
    return (
      <div className="App d-flex flex-column">
        <a className="skip-link" href="#maincontent">Skip to main</a>

        <div className="nav-wrapper">
          <Navbar user={user && user.attributes.email}  />
          {/* handleSignout={this.handleSignout} handleSignin={this.handleSignin} */}
        </div>

        <div id="maincontent">
        {loaded && <UserContext.Provider value={{ user, userAttributes }}>
          <Suspense fallback={<div></div>}>
          <Switch>
            {/* <Route exact path="/profile">
              <ProfilePage user={user} userAttributes={userAttributes} />
            </Route>
            <Route exact path="/signin">
              {user ? <Redirect to="/quiz" /> : <AuthPage authState={'signIn'} authError={authError} /> }
            </Route>
            <Route exact path="/signup">
              {user ? <Redirect to="/quiz" /> : <AuthPage authState={'signUp'} authError={authError} /> }
            </Route> */}
            <Route path="/quiz">
              <QuizzesPage user={user} />
            </Route>
            <Route path="/">
              <AppHomePage user={user}  />
              {/* handleSignin={this.handleSignin} */}
            </Route>
          </Switch>
          </Suspense>
        </UserContext.Provider>}
        </div>

        <CookieConsent
        disableStyles={true}
        location={OPTIONS.BOTTOM}
        buttonClasses="btn btn-primary"
        containerClasses="CookieConsent alert alert-warning col-lg-12 p-3 mb-0 border-0">
          This website uses cookies to enhance the user experience.
        </CookieConsent>
      </div>
    );
  }
}

export default withRouter(App);
