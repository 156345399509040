// import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
// import 'primeicons/primeicons.css';

import React from 'react';
import ReactDOM from 'react-dom';

// import { Provider } from 'react-redux'
// import store from './store'

import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports'


Amplify.configure(aws_exports);

//<Provider store={store}></Provider>
ReactDOM.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
