import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';

export default ({ user, handleSignout, handleSignin }) => {
    return (
        <Navbar className="navbar-top" sticky="top">
            
            <Link className="navbar-brand logo" to="/">  <span className="logo-quiz">Quiz</span>Effects  </Link>
            <Nav className="mr-auto">
                {/*<Nav.Link href="#features">Features</Nav.Link>
                <Nav.Link href="#pricing">Pricing</Nav.Link>*/}
            </Nav>

            {user ? <Nav className="justify-content-end">
                <Navbar.Text className="d-none d-sm-block">{user}</Navbar.Text>
                <Link className="nav-link" role="button" to="/profile">Profile</Link>
                <Nav.Link onClick={handleSignout}>Logout</Nav.Link>
                {/*<div className="btn btn-primary" onClick={handleSignout} >Logout</div>*/}
            </Nav> : <Nav>
                    {/* <ButtonToolbar>
                        <div variant="" className="btn btn-outline-primary btn-sm-sm mr-2" onClick={e => handleSignin(e, 'signIn')} >Login</div> <div className="btn btn-primary btn-sm-sm" onClick={e => handleSignin(e, 'signUp')} >Sign up free</div>
                    </ButtonToolbar> */}
                </Nav>

            }




        </Navbar>
    )
};