/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "deployapi",
            "endpoint": "https://5yba4aol57.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "lrslambda",
            "endpoint": "https://d83u5ryh1k.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "quizerapi",
            "endpoint": "https://xtihawg6y8.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://2iizsmkcmbdcxossv6xt76iowa.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:7c4cf78f-1a8d-4d85-b9ed-90fbb46d237a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mD0PR15E4",
    "aws_user_pools_web_client_id": "1mmgvlp0rqsq7inuc1vb88m54n",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "quiz-studio-storage-production",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
